<template>
    <b-row>
        <b-col md="12">
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">{{$t('Search')}}</label>
                        <b-form-input
                            v-model="searchTerm"
                            :placeholder="$t('Search...')"
                            type="text"
                            class="d-inline-block"
                        />
                    </div>
                </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
                :columns="columns"
                :rows="this.$store.state.ads.ads"
                :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }"
                :select-options="{enabled: false}"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                  }"
            >
                <template
                    slot="table-row"
                    slot-scope="props"
                >

                    <!-- Column: Title -->
                    <span
                        v-if="props.column.field === 'Title'"
                        class="text-nowrap"
                    >
                          <b-avatar
                              :src="props.row.image_full_path"
                              class="mx-1"
                          />
                          <span class="text-nowrap">{{ props.row.title }}</span>
                    </span>

                    <!-- Column: Description -->
                    <span v-else-if="props.column.field === 'Description'">
                      <p>{{ props.row.description }}</p>
                    </span>

                    <!-- Column: Start Date -->
                    <span v-else-if="props.column.field === 'Status'">
                        <b-badge :variant="adStatus(props.row.ad_status)">
                            {{ props.row.ad_status }}
                          </b-badge>
                    </span>

                    <!-- Column: View Count -->
                    <span v-else-if="props.column.field === 'View Count'">
                        <p>{{ props.row.view_count }} / {{ props.row.max_view_count }}</p>
                    </span>

                    <!-- Column: Click Count -->
                    <span v-else-if="props.column.field === 'Click Count'">
                        <p>{{ props.row.click_count }}</p>
                    </span>

                    <!-- Column: Start Date -->
                    <span v-else-if="props.column.field === 'startDate'">
                      {{ handleDate(props.row.start_date) }}
                    </span>

                    <!-- Column: End Date -->
                    <span v-else-if="props.column.field === 'endDate'">
                      {{ handleDate(props.row.end_date) }}
                    </span>


                    <!-- Column: Status -->
                    <span v-else-if="props.column.field === 'Url'">
                        <b-button
                            variant="primary"
                            :href="'//' +props.row.url"
                            target="_blank"
                        >
                                <div class="d-flex align-items-center text-body">
                                    <feather-icon
                                        icon="LinkIcon"
                                        class="mr-25  text-white"
                                    />
                                    <span class="font-weight-bold text-white">{{$t('URL')}}</span>
                                </div>
                            </b-button>
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                     <span>
                        <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item @click="editAd(props.row.id)">
                            <feather-icon
                                icon="Edit2Icon"
                                class="mr-50"
                            />
                            <span>{{$t('Edit')}}</span>
                          </b-dropdown-item>
                          <b-dropdown-item v-b-modal.modal-danger @click="changeActiveAd(props.row.id)">
                            <feather-icon
                                icon="TrashIcon"
                                class="mr-50"
                            />
                            <span>{{$t('delete')}}</span>
                          </b-dropdown-item>
                        </b-dropdown>

                      </span>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['3','5','10']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-col>
        <!--   Confirm Delete Modal     -->
        <b-modal
            id="modal-danger"
            ok-variant="danger"
            :ok-title="$t('delete')"
            :cancel-title="$t('cancel')"
            modal-class="modal-danger"
            centered
            :title="$t('Delete Ad')"
            @ok="deleteAd()" @cancel=""
        >
            <b-card-text>
                {{$t('You are going to delete this ad permanently.')}} <br>{{$t('Are you sure?')}}
            </b-card-text>
        </b-modal>
    </b-row>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCol,
    BRow,
    BButton,
    BModal,
    BCardText,
    VBModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {VueGoodTable} from 'vue-good-table'
import store from "@/store";

export default {
    name: "liste",
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BCardText,
        BDropdownItem,
        BCol,
        BRow,
        BButton,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: this.$t('title'),
                    field: 'Title',
                },
                {
                    label: this.$t('Description'),
                    field: 'Description',
                },
                {
                    label: this.$t('Status'),
                    field: 'Status',
                },
                {
                    label: this.$t('View Count'),
                    field: 'View Count',
                },
                {
                    label: this.$t('Click Count'),
                    field: 'Click Count',
                },
                {
                    label: this.$t('Start Date'),
                    field: 'startDate',
                },
                {
                    label: this.$t('End Date'),
                    field: 'endDate',
                },
                {
                    label: this.$t('URL'),
                    field: 'Url',
                },
                {
                    label: this.$t('action'),
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            activeAdID: null,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
        adStatus(key) {
            const statusColor = {
                /* eslint-disable key-spacing */
                someWord      : 'light-primary',
                active : 'light-success',
                ended     : 'light-danger',
                scheduled     : 'light-warning',
                Applied      : 'light-info',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
    },
    methods: {
        handleDate(laravelDate) {
            let date = new Date(laravelDate);
            return date.toLocaleDateString();
        },
        editAd(id) {
            this.$router.push({name: 'ads-edit', params: {id: id}})
        },
        deleteAd() {
            this.$store.dispatch("ads/deleteAd", this.activeAdID)
                .then(res => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Success'),
                                icon: 'InfoIcon',
                                text: this.$t('Ad deleted successfully'),
                                variant: 'success',
                            },
                        },
                        {
                            position: 'bottom-right',
                        });
                    this.getAllAds();
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                });
        },
        changeActiveAd(id) {
            this.activeAdID = id;
        },
        getAllAds() {
            this.$store.dispatch("ads/getAds")
                .then((res) => {
                })
                .catch((err) => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        },
    },
    created() {
        this.getAllAds();
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
